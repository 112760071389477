import React from 'react';

export default () => (
  <form
    name="contact"
    method="post"
    action="/success"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    className='lg:w-1/2'
    style={{margin: "auto"}}
  >
    <input type="hidden" name="bot-field" />
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
        Name
      </label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-loose focus:outline-none focus:shadow-outline"
        id="name"
        type="text"
        placeholder="Name"
        name="name"
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
        Email Address
      </label>
      <input
        class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-loose focus:outline-none focus:shadow-outline"
        id="email"
        type="text"
        placeholder="Email Address"
        name="emailAddress"
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="phone">
        Phone Number
      </label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-loose focus:outline-none focus:shadow-outline"
        id="phone"
        type="text"
        placeholder="Phone Number"
        name="phoneNumber"
      />
    </div>
    <div class="mb-6">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="message">
        Message
      </label>
      <textarea
        class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-loose focus:outline-none focus:shadow-outline"
        id="message"
        type="text"
        rows={4}
        name="message"
      />
    </div>
    <div class="flex items-center justify-between">
      <p>
        <button
          type="submit"
          className="bg-primary hover:bg-primary-darker rounded text-white py-3 px-8"
        >
          Send
        </button>
      </p>
    </div>
  </form>
);
