import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import { Link } from 'gatsby';
import PieImage from '../svg/pie.jpg';
import GraphImage from '../svg/graph.jpg';
import AnalyticsImage from '../svg/analytics.png';
import socialMedia from '../svg/socialMedia.jpg';
import seo from '../svg/seo.jpg';
import seo2 from '../svg/seo2.png';
import branding from '../svg/branding.png';
import ContactUsForm from '../components/contactUsForm';

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-32">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Modern day branding from a results driven agency!
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            A full service agency focused on strategy, design and analytics
          </p>
          <p className="mt-4 text-gray-600">
            We help businesses improve their online presence and remodel their approach to become a
            market leader!
          </p>
          <p className="mt-4 text-gray-600">
            Implement your marketing budget with purpose and achieve noteworthy results!
          </p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <div className="container mx-auto text-center py-20 lg:pb-20 lg:pt-32">
      <h2 className="text-3xl lg:text-5xl font-semibold">Services</h2>
    </div>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Research</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We conduct market research to help our clients understand their target market and
            competition. This includes surveys, focus groups, and competitor analysis.
          </p>
        </div>
      }
      secondarySlot={<img src={AnalyticsImage} width="431px" height="575px" alt="horse" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Branding</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We help our clients develop a strong brand identity through logo design, brand
            guidelines, and messaging.
          </p>
        </div>
      }
      secondarySlot={<img src={branding} width="431px" height="575px" alt="horse" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Website Design and SEO</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We design and develop websites that are tailored to our clients' specific needs and
            goals. This includes e-commerce websites, landing pages, and microsites. We optimize our
            clients' websites for search engines to help them rank higher in search results and
            reach more customers.
          </p>
        </div>
      }
      secondarySlot={<img src={seo2} width="431px" height="575px" alt="horse" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Social Media Management</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We create and manage social media accounts for our clients, including Facebook, Twitter,
            Instagram, and LinkedIn. We also create and schedule social media posts and develop
            social media advertising campaigns.
          </p>
        </div>
      }
      secondarySlot={<img src={socialMedia} alt="horse" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">PPC</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We create and manage pay-per-click advertising campaigns for our clients on platforms
            such as Google Ads, Facebook and Instagram Ads, TikTok Ads and Bing Ads.
          </p>
        </div>
      }
      secondarySlot={<img src={GraphImage} width="431px" height="575px" alt="horse" />}
    />
    <section id="features" className="py-20 lg:pb-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12 align-items-stretch">
          <Card className="mb-8 flex-1 m-3">
            {/* Change Color + Size of header */}
            <p className="font-semibold text-xl text-danger">Brand development & Management</p>
            <p className="mt-4">
              Developing and scaling your brand is and will always remain priority one. We focus on
              creating an online presence for your business and ensuring that the business’ sales
              statistics remain at the forefront. Building a reliable and trustworthy brand will
              last much longer than any marketing campaign and should be the goal.
            </p>
            <div className="mt-5">
              <Link
                to="/pricing"
                className="p-3 bg-primary hover:bg-primary-darker rounded text-white"
              >
                Find Out More!
              </Link>
            </div>
          </Card>
          <Card className="mb-8 flex-1 m-3">
            <p className="font-semibold text-xl">Website rebuild, Brand development & Management</p>
            <p className="mt-4">
              Full website rebuild and brand development campaign to ensure the new look will be at
              the forefront of your client. We ensure that the correct market research is done to
              create an appropriate new look and subsequent customer loyalty.
            </p>
            <div className="mt-5">
              <Link
                to="/rebuilding"
                className="p-3 bg-primary hover:bg-primary-darker rounded text-white"
              >
                Find Out More!
              </Link>
            </div>
          </Card>
          <Card className="mb-8 flex-1 m-3">
            <p className="font-semibold text-xl">eCommerce site build & Brand Management</p>
            <p className="mt-4">
              Full eCommerce site build that ensures your clients are able to browse and shop with
              easy. Site hosting and brand management is included to ensure clients can purchase and
              orders can be fulfilled with easy.
            </p>
            <div className="mt-5">
              <Link
                to="/rebuilding"
                className="p-3 bg-primary hover:bg-primary-darker rounded text-white"
              >
                Find Out More!
              </Link>
            </div>
          </Card>
        </div>
      </div>
    </section>
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3 px-8">
            <StatsBox
              primaryText="76%"
              secondaryText="Shoppers prefer to shop online because they can find more information."
            />
          </div>
          <div className="w-full sm:w-1/3 px-8">
            <StatsBox
              primaryText="90%"
              secondaryText="Small businesses experience growth due to social media marketing."
            />
          </div>
          <div className="w-full sm:w-1/3 px-8">
            <StatsBox
              primaryText="43%"
              secondaryText="Increase in online shopping in South Africa yearly."
            />
          </div>
        </div>
      </div>
    </section>
    {/* <section id="testimonials" className="py-20 lg:py-24">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map((customer) => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section id="contactUs" className="py-20 lg:py-24">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold mb-5">Contact Us</h2>
        <div class="w-full max-w-xs">
          <ContactUsForm />
        </div>
      </div>
    </section>
  </Layout>
);

export default Index;
